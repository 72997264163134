
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { platforms } from "@/store/pinia/Platforms";
import { storeToRefs } from "pinia";
import DatePicker from "vue3-persian-datetime-picker";
import { getLocale } from "@/core/services/HelpersFun";
interface DataInterface {
  name: string;
  sort: number;
  short_description: string;
  title_description: string;
  platforms_price: [] | any;
  base_discounts: [] | any;
  currency_code: string;
  is_free: string;
  rules_description: any;
  rules: any;
  finish_datetime: string;
  start_datetime: string;
  boundless_base_discount: number;
  boundless_discount_percent: string;
  boundless_description: string;
}

export default defineComponent({
  name: "create-modal-modal",
  emits: ["refreshList"],
  components: { DatePicker },
  props: {
    id: {
      type: Number,
      default: undefined,
    },
    type: {
      type: String,
      default: "create", // create - copy - edit
    },
  },
  setup(props, { emit }) {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const store = useStore();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const errorsBag = ref({});
    const editMode = ref(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const targetData = ref<DataInterface>({
      name: "",
      sort: 0,
      short_description: "",
      title_description: "",
      platforms_price: {
        whatsapp: 0,
        telegram: 0,
        bale: 0,
        eitaa: 0,
        rubika: 0,
      },
      base_discounts: {
        whatsapp: 0,
        telegram: 0,
        bale: 0,
        eitaa: 0,
        rubika: 0,
      },
      finish_datetime: "",
      start_datetime: "",
      currency_code: "",
      is_free: "no",
      rules: {
        expire_after: 1,
        bulk_messages_max_count: null,
      },
      rules_description: [
        {
          type: true,
          desc: "",
        },
      ],
      boundless_base_discount: 0,
      boundless_discount_percent: "",
      boundless_description: "",
    });

    const rules = ref({
      name: [
        {
          required: true,
          message: "لطفا نام پکیج را وارد کنید",
          trigger: "blur",
        },
      ],
      price: [
        {
          required: true,
          message: "لطفا قیمت پکیج را وارد کنید",
          trigger: "blur",
        },
      ],
      short_description: [
        {
          required: true,
          message: "توضیح کوتاه پکیج را وارد کنید",
          trigger: "blur",
        },
      ],
    });

    const submit = () => {
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      errorsBag.value = {};
      let data = { ...targetData.value };
      data.rules = [];

      data.rules.push({
        rule: "expire_after",
        value: targetData.value.rules.expire_after,
      });

      data.rules.push({
        rule: "bulk_messages_max_count",
        value: targetData.value.rules.bulk_messages_max_count,
      });

      data.rules_description = data.rules_description.filter((item) => {
        if (item.desc !== "") return item;
      });
      data.rules_description = data.rules_description.map((item) => {
        item.type = item.type ? "yes" : "no";
        return item;
      });
      let url = editMode.value
        ? props.type === "copy"
          ? "admin/package/create"
          : `admin/package/${props.id}/edit`
        : "admin/package/create";

      ApiService.post(url, data)
        .then(() => {
          hideModal(newTargetModalRef.value);
          emit("refreshList");
          editMode.value = false;
          clear();
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
          if (response!.status === 400) {
            errorsBag.value = response.data.data;
          }
          ElNotification({
            title: "خطا",
            message: "خطا در ایجاد پکیج",
            type: "error",
          });
        })
        .finally(() => {
          if (submitButton.value) {
            //Deactivate indicator
            submitButton.value?.removeAttribute("data-kt-indicator");
            // eslint-disable-next-line
              submitButton.value!.disabled = false;
          }
        });
    };

    const currencies = ref();
    const getCurrencies = () => {
      ApiService.get("admin/currency")
        .then(({ data }) => {
          currencies.value = data.data.currencies.data;
        })
        .catch(({ response }) => {
          store.commit(Mutations.SET_ERROR, response.data);
        });
    };

    const platformsHolder = platforms();
    const { platformsList } = storeToRefs(platformsHolder);

    const rulesHold = reactive([1]);
    const clear = () => {
      editMode.value = false;
      targetData.value = {
        name: "",
        sort: 0,
        platforms_price: [],
        base_discounts: [],
        currency_code: "IRT",
        is_free: "yes",
        short_description: "",
        title_description: "",
        finish_datetime: "",
        start_datetime: "",
        rules: {
          expired_after: 1,
        },
        rules_description: [
          {
            type: true,
            desc: "",
          },
          {
            type: true,
            desc: "",
          },
        ],
        boundless_base_discount: 0,
        boundless_discount_percent: "",
        boundless_description: "0",
      };
    };
    // eslint-disable-next-line @typescript-eslint/ban-types
    const showPackage = (id) => {
      loading.value = true;
      ApiService.get(`admin/package/${id}`)
        .then(({ data }) => {
          editMode.value = true;
          let holder = data.data.package;
          holder.rules.forEach((item) => {
            if (item.rule === "expire_after") {
              targetData.value.rules["expire_after"] = item.value;
            } else if (item.rule === "bulk_messages_max_count") {
              targetData.value.rules["bulk_messages_max_count"] = item.value;
            }
          });

          targetData.value.name = holder.name;
          targetData.value.short_description = holder.short_description;
          targetData.value.title_description = holder.title_description;
          targetData.value.platforms_price = holder.platforms_price;

          targetData.value.base_discounts =
            holder.base_discounts &&
            Object.keys(holder.base_discounts).length > 0
              ? holder.base_discounts
              : {
                  whatsapp: 0,
                  telegram: 0,
                  bale: 0,
                  eitaa: 0,
                  rubika: 0,
                };

          targetData.value.finish_datetime = holder.finish_datetime;
          targetData.value.start_datetime = holder.start_datetime;

          targetData.value.currency_code = holder.currency_code;
          targetData.value.is_free = holder.is_free;
          targetData.value.rules_description = holder.rules_description;
          targetData.value.boundless_base_discount =
            holder.boundless_base_discount;
          targetData.value.boundless_discount_percent =
            holder.boundless_discount_percent;
          targetData.value.boundless_description = holder.boundless_description;
        })
        .catch((e) => {
          store.commit(Mutations.SET_ERROR, e.response.data);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const addRules = () => {
      targetData.value.rules_description.push({
        type: true,
        desc: "",
      });
    };

    const removeRule = (indexRule) => {
      targetData.value.rules_description =
        targetData.value.rules_description.filter(
          (item, index) => index !== indexRule
        );
    };
    watch(
      () => props.id,
      (id) => {
        if (!id) return clear();

        showPackage(id);
      }
    );

    onMounted(() => {
      getCurrencies();
    });

    return {
      translate,
      submitButton,
      targetData,
      submit,
      loading,
      formRef,
      rules,
      newTargetModalRef,
      addRules,
      rulesHold,
      errorsBag,
      editMode,
      removeRule,

      platformsList,
      currencies,
      getLocale,
    };
  },
});

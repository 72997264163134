
import { defineComponent, onMounted, ref } from "vue";
import { ElNotification } from "element-plus";
import ApiService from "@/core/services/ApiService";
import CreatePackageModal from "@/views/pages/Admin/transactions/components/CreatePackageModal.vue";
import Status from "@/components/Status.vue";
import { translate } from "@/core/services/HelpersFun";
export default defineComponent({
  name: "kt-table-packages-list",
  props: {
    widgetClasses: String,
    id: {
      type: Number,
      default: undefined,
    },
  },
  components: {
    Status,
    CreatePackageModal,
  },
  setup() {
    const actionType = ref();
    const loading = ref(false);
    const list = ref();
    const editPackageId = ref();
    const createPackageModalRef = ref();
    const svg = `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `;

    const permission = ref();

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
      }, 3000);

      getItems();
    });

    const deletePackage = (id) => {
      if (!confirm("آیا از حذف این پکیج اطمینان دارید؟")) return;

      ApiService.post(`admin/package/${id}/delete`, {})
        .then(() => {
          getItems();
        })
        .catch(() => {
          ElNotification({
            title: "خطا",
            message: "خطا در حذف پکیج",
            type: "error",
          });
        });
    };

    const getItems = () => {
      loading.value = true;
      editPackageId.value = undefined;
      ApiService.get("admin/package")
        .then(({ data }) => {
          list.value = data.data.packages;
        })
        .catch(() => {
          // ElNotification({
          //   title: "خطا",
          //   message: "خطا در بارگزاری لیست پکیج ها",
          //   type: "error",
          // });
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const pagination = ref();
    const handleSizeChange = (val: number) => {
      return;
    };
    const handleCurrentChange = (val: number) => {
      return;
    };
    const setData = (botIdValue, step) => {
      editPackageId.value = botIdValue;
    };

    return {
      list,
      loading,
      svg,
      pagination,
      handleCurrentChange,
      handleSizeChange,
      getItems,
      deletePackage,
      setData,
      editPackageId,
      createPackageModalRef,
      // getType,
      translate,
      actionType,
      permission,
    };
  },
});
